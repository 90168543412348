.credentials {
  padding: 80px 30px 50px;
  position: relative;
}
.credentials .close-btn {
  right: 30px;
  z-index: 2;
}
.credentials .account-button {
  position: fixed;
  bottom: 0;
  padding: 20px 0;
  background-color: #f8f8f8;
}

.credentials .account-button .action-btn {
  width: 120px;
}
.credentials .account-button .action-btn:hover:not(:disabled) {
  background-color: #007de4 !important;
  color: #fff !important;
}

.credentials .right-data {
  background-image: url(../assets/images/svg/data-background.svg);
  background-repeat: no-repeat;
  width: 100%;
  min-height: 225px;
  background-size: cover;
  position: relative;
  border-radius: 5px;
}
.credentials .right-data::after {
  content: "";
  position: absolute;
  background-image: url(../assets/images/svg/edexa.svg);
  right: 15px;
  width: 171px;
  height: 55px;
  top: 15px;
  background-repeat: no-repeat;
  background-size: 50%;
  background-position: right top;
}
.padding-col {
  padding-left: 0;
}

@media only screen and (max-width: 1440px) {
  .credentials {
    padding: 80px 30px 100px;
  }
}
@media only screen and (max-width: 1023px) {
  .credentials .right-data {
    margin-top: 10px;
  }
}
@media screen and (max-width: 768px) {
  .credentials {
    padding: 130px 15px 50px;
  }
  .credentials .account-button .action-btn {
    width: 80px;
    height: 40px;
  }
  .credentials .account-button {
    padding: 10px 0.75rem 0;
    left: 0;
    width: 100%;
  }
}
@media screen and (max-width: 767px) {
  .padding-col {
    padding-left: 12px;
  }
}
