.footer{
    background-color: #f8f8f8;
    /* position: fixed; */
    bottom: 0;
    padding: 20px 30px;
    height: 60px;
    z-index: 2;
    margin: 0 10px;
}
.footer a{
    margin-right: 20px;
}
.footer a:last-child{
    margin-right: 0;
}


@media screen and (max-width: 767px) {
    .footer{
        padding: 10px;
        justify-content: center !important;
    }
}