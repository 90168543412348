@import "bootstrap/dist/css/bootstrap.min.css";
@import url("https://fonts.googleapis.com/css2?family=Lato:wght@300;400;700;900&display=swap");
@import "style/fontawesome.min.css";
@import "style/common.css";
@import "style/form-element.css";
@import "style/header.css";
@import "style/footer.css";
@import "style/dashboard.css";
@import "style/holders.css";
@import "style/issue-credential.css";
@import "style/credentials.css";
@import "style/information.css";
@import "style/reivew.css";

.App {
  text-align: center;
}

.App-logo {
  animation: App-logo-spin infinite 20s linear;
  height: 40vmin;
  pointer-events: none;
}

.App-header {
  background-color: #282c34;
  min-height: 100vh;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  font-size: calc(10px + 2vmin);
  color: white;
}

.App-link {
  color: #61dafb;
}

@keyframes App-logo-spin {
  from {
    transform: rotate(0deg);
  }
  to {
    transform: rotate(360deg);
  }
}

body {
  font-family: Lato;
  background-color: #f8f8f8;
}
.loader-wrapper {
  position: fixed;
  top: 0;
  left: 0;
  height: 100%;
  width: 100%;
  z-index: 1000000000 !important;
  display: flex;
  align-items: center;
  justify-content: center;
}
.loader-wrapper:before {
  content: "";
  position: absolute;
  left: 0;
  top: 0;
  width: 100%;
  height: 100%;
  background: rgba(0, 0, 0, 0.5);
}

.unauthorized {
  height: 90vh;
  width: 100%; 
  text-align: center;
  display: flex;
  justify-content: center;
  align-items: center;
  flex-direction: column;
}