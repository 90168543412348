/* Chrome, Safari, Edge, Opera */
input::-webkit-outer-spin-button,
input::-webkit-inner-spin-button {
  -webkit-appearance: none;
  margin: 0;
}

/* Firefox */
input[type=number] {
  -moz-appearance: textfield;
}

/* country phone number picker style */
.MuiFormControl-root {
  color: inherit !important; 
  border: 1px solid #DADCE0 !important;
}
.MuiFormControl-root.is-invalid.form-control {
  border: 1px solid #dc3545 !important;
}
.MuiInputBase-root {
  /* color: inherit !important; */
  /* border: 1px solid #DADCE0; */
  border-radius: 5px;
  padding: 8px;
  font-family: inherit !important;
  font-size: 14px !important;
  line-height: 18px !important;
}
.MuiInput-underline:hover:not(.Mui-disabled):before, .MuiInput-underline:before{
  display: none !important;
}
.Mui-focused{
  visibility: 0 !important
}
.MuiInput-underline:after{
  display: none !important;
}