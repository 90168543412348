.dashboard {
  padding: 80px 30px 0px;
}
.list-box {
  background-color: #fff;
  padding: 20px;
}

.list-box .box {
  width: 19%;
  height: 110px;
  padding: 30px;
  border-radius: 5px;
  cursor: pointer;
}
.list-box .box:nth-child(1) {
  background-color: #cffbf8;
}
.list-box .box:nth-child(2) {
  background-color: #fcd8da;
}
.list-box .box:nth-child(3) {
  background-color: #f2e8ff;
}
.list-box .box:nth-child(4) {
  background-color: #ddeffc;
}
.list-box .box:last-child {
  background-color: #fbf7db;
}
.dashboard-data {
  /* height: 500px; */
}
.table-section {
  /* border: 1px solid #DADCE0; */
  border-radius: 5px;
}
.table-section thead {
  background-color: #e8f0fe;
  position: sticky;
  top: 0;
  z-index: 1;
}
.table-section tbody tr {
  border-bottom: 1px solid #dadce0;
}
.table-section thead th,
tbody td {
  border: none;
}
.table-section thead th,
tbody th,
tbody td {
  padding: 17px !important;
}
.table-section .status-data {
  padding: 17px 17px 17px 35px !important;
}
.thumb-horizontal,
.thumb-vertical {
  background-color: #007de4;
  border-radius: 10px;
}

.table-section .holder-profile {
  width: 25px;
  height: 25px;
  border-radius: 50%;
  overflow: hidden;
}
.table-section .holder-profile img {
  width: 100%;
  height: 100%;
}
.table-section .status {
  width: 10px;
  height: 10px;
  border-radius: 50%;
  position: absolute;
  left: 17px;
  top: 22px;
}
.table .active {
  background-color: #28c76f;
}
.table .deactive {
  background-color: #ea2027;
}

.tips-box {
  height: 100%;
  padding: 30px 20px;
  position: relative;
}
.tips-box button {
  position: absolute;
  bottom: 30px;
}

@media only screen and (max-width: 1024px) {
  .list-box {
    justify-content: left !important;
  }
  .list-box .box {
    width: 30%;
    margin: 20px 20px 0 0;
  }
  .tip {
    margin-top: 25px;
  }
}
@media only screen and (max-width: 768px) {
  .dashboard {
    padding: 120px 15px 80px;
  }
  .list-box {
    justify-content: space-between !important;
  }
  .list-box .box {
    width: 47%;
    margin: 10px 0 0;
  }
  .tip {
    margin-top: 15px;
  }
}
@media only screen and (max-width: 767px) {
  .dashboard-data {
    flex-wrap: wrap;
  }
  .list-box .box {
    width: 100%;
    margin: 10px 0 0;
  }

  .tip {
    margin-top: 15px;
  }
}
