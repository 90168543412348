.verifier-list {
  max-height: 336px;
  overflow-y: auto;
}
.verifier-item:hover {
  background: #007de418;
}
.verifier-item label {
  color: #868686;
}

/* scroll bar style */
.verifier-list::-webkit-scrollbar {
  width: 4px;
  display: none;
}

.verifier-list:hover::-webkit-scrollbar {
  display: block;
}

.verifier-list::-webkit-scrollbar-track {
  border-radius: 0px;
}

.verifier-list::-webkit-scrollbar-thumb {
  border-radius: 0px;
  -webkit-box-shadow: inset 0 0 6px rgba(0, 0, 0, 0.295);
}

/* disable horizontal scroll */
.credential-scroll > div {
  overflow-x: hidden !important;
}
.searchable_dropdown > div:first-child > div {
  padding: 5px 10px;
}
.selectError > div {
  border-color: #dc3545;
}
/* .selectError ~ .invalid-feedback {
  display: block;
} */
.searchable_dropdown .css-1okebmr-indicatorSeparator {
  display: none;
}
.searchable_dropdown .css-1hb7zxy-IndicatorsContainer {
  margin: -8px;
}
.searchable_dropdown .css-tlfecz-indicatorContainer {
  color: #4c4f53;
  font-size: 10px;
}

.searchable_dropdown .css-14el2xx-placeholder {
  font-size: 14px;
  color: #4c4f53;
}

@media only screen and (max-width: 1024px) {
  .credential-scroll > div {
    padding: 17px;
  }
}
