.information{
    margin-top: 20px !important;
    height: 280px;
    position: relative;
}
.information .basic-info{
    background-color: #FEC837;
    /* height: 280px; */
    padding: 30px;
    background-image: url(../assets/images/svg/rocket.svg);
    background-repeat: no-repeat;
    background-position: right 20px bottom;
    height: 100%;
    background-size: 45%;
}
.information-section .upload-logo{
    width: 150px;
    height: 150px;
}
.information-section .profile-img{
    position: relative;
    overflow: hidden;
}
.information-section .profile-img::before{
    position: absolute;
    content: "";
    width: 140%;
    height: 140%;
    top: -30px;
    left: -30px;
    border: 25px solid white;
    background: transparent;
    opacity: .5;
    border-radius: 50%;
    box-shadow: inset 0 5px white;
}
.information .more-link{
    position: absolute;
    bottom: 30px;
    left: 30px;
}
.information .more-link:hover{
    color: inherit;
}
.information .info-list{
    padding: 30px;
}
.information .info-list i{
    left: 0;
    top: 2px;
}

@media screen and (max-width:767px) {
    .information{
        height: auto;
    }
    .information .info-list, .information .basic-info{
        padding: 15px;
        height: 200px;
        margin-bottom: 15px;
    }
    .information-section .logo-section{
        flex-direction: column;
    }
    .information-section .logo-section .upload-logo{
        height: auto;
    }
}