body {
  /* font-size: calc(1.3em + 1vmin); */
  font-family: "Lato";
  font-weight: 400;
  /* modal is adding extra padding so overriding from here */
  padding-right: 0 !important;
}

p {
  margin: 0;
  margin-bottom: 0;
}

a {
  text-decoration: none !important;
}

button {
  border: none;
}

.width-920 {
  max-width: 920px;
  width: 100%;
}

.width-900 {
  max-width: 900px;
  width: 100%;
}

.max-width-100 {
  max-width: 100% !important;
}

.minheight-100 {
  min-height: 100vh;
}

/* text properties */

.text-wrap {
  overflow-wrap: break-word !important;
}

/* cursor properties */

.cursor-pointer {
  cursor: pointer;
}

/* border-radius */

.rounded-10 {
  border-radius: 10px;
}

.rounded-15 {
  border-radius: 15px;
}

.text-right {
  text-align: right;
}

.text-left {
  text-align: left;
}

.border-grey {
  border: 1px solid #dadce0 !important;
}
.border-blue {
  border: 1px solid #007de4 !important;
}

.b-none {
  border: none;
}

/* Fonts  */

.font-50 {
  font-size: 50px;
  line-height: 22px;
}

.font-40 {
  font-size: 40px;
  line-height: 35px;
}

.font-36 {
  font-size: 36px;
  line-height: 44px;
}

.font-26 {
  font-size: 26px;
  line-height: 36px;
}

.font-16 {
  font-size: 16px;
  line-height: 20px;
}

.font-20 {
  font-size: 20px;
  line-height: 30px;
}

.font-14 {
  font-size: 14px;
  line-height: 18px;
}

.font-13 {
  font-size: 13px;
  line-height: 26px;
}

.logout-btn{
  color: gray;
  font-weight: 600;

}

.logout-btn:hover{
  background-color: #007de4 !important;
  color: #ffffff  ;
}

.font-12 {
  font-size: 12px;
  line-height: 22px;
}

.font-11 {
  font-size: 11px;
  line-height: 16px;
}

/* font-colors  */

.color-light-grey {
  color: #868686;
}

.color-blue {
  color: #007de4;
}

.color-dark-blue {
  color: #073d83;
}

.color-black1,
.active-color-black1:hover {
  color: #0d0f12;
}

.color-black2,
.active-color-black2:hover,
.active-color-black2:focus,
.active-color-black1:active,
.active-color-black1.active {
  color: #4c4f53;
}

.color-blue,
.active-color-blue:hover,
*:checked + .active-color-blue {
  color: #007de4;
}

*:checked + .active-border-blue,
.active-border-blue:hover {
  border-color: #007de4;
}

.color-white {
  color: #ffffff;
}

.color-light-white {
  color: #ffffffa3;
}

.color-green {
  color: #7ac142;
}

.color-grey {
  color: #dadce0;
}

/* background */

.bg_white {
  background: #ffffff;
}

.bg-gray1,
.nav-tabs .nav-link.bg-gray1,
.form-control:read-only.bg-gray1,
.form-select:disabled.bg-gray1 {
  background-color: #f8f8f8;
}

.nav-tabs .nav-link:not(.active) {
  border: 1px solid #ddd;
}

.bg-lightblue {
  background: #e8f0fe !important;
}

.bg-blue {
  background: #007de4 !important;
}

.bg-grey,
.edit-input:focus {
  background-color: #dadce0;
}

/* margin */

.mt-30 {
  margin-top: 30px;
}

.mt-20 {
  margin-top: 20px;
}

.mt-10 {
  margin-top: 10px;
}

.mb-30 {
  margin-bottom: 30px;
}

.mb-25 {
  margin-bottom: 25px;
}

.mb-20 {
  margin-bottom: 20px;
}

.mb-10 {
  margin-bottom: 10px;
}

.mr-30 {
  margin-right: 30px;
}

.mr-20 {
  margin-right: 20px;
}

.mr-15 {
  margin-right: 15px;
}

.mr-10 {
  margin-right: 10px;
}

.ml-10 {
  margin-left: 10px;
}

.mt-0 {
  margin-top: 0;
}

.my_10 {
  margin-top: 10px;
  margin-bottom: 10px;
}

.pb-70 {
  padding-bottom: 70px;
}

.pt-70 {
  padding-top: 70px;
}

.px-10 {
  padding: 0 10px;
}

.p-0 {
  padding: 0;
}

.py-20 {
  padding: 20px;
}

.py-15 {
  padding: 15px 0;
}

.py-10 {
  padding: 10px 0;
}

.p_5 {
  padding: 5px !important;
}

.p-10 {
  padding: 10px;
}

.p-20 {
  padding: 20px;
}

.pr-10 {
  padding-right: 10px;
}

.pl-25 {
  padding-left: 25px !important;
}

.pl-0 {
  padding-left: 0;
}

.pr-0 {
  padding-right: 0;
}

.pb-20 {
  padding-bottom: 20px;
}

.pb-30 {
  padding-bottom: 30px;
}

/* font-weight  */

.fw-900 {
  font-weight: 900;
}

/* disable  */

.disable {
  opacity: 0.3;
  pointer-events: none !important;
}

.icon-sm {
  font-size: 0.9em;
}

.w-60 {
  width: 60%;
}

.w-40 {
  width: 40%;
}

.w-250px {
  width: 250px;
}

.w-115px {
  width: 115px;
  min-width: 115px;
}

.h-50px {
  height: 50px;
}

.h-40 {
  height: 40px;
}

.dropdown-menu {
  font-size: inherit;
  z-index: 3;
}

.form-control:focus {
  box-shadow: none;
}
.table-top-border{
  border-top: 1px solid #dadce0 !important;
}

/* form select */
.form-select:disabled {
  background-color: #e9ecef !important;
  cursor: not-allowed;
}

@media (max-width: 1680px) {
}

@media (max-width: 1399px) {
  /* .h-50px {
        height: 38px;
    } */
  .font-36 {
    font-size: 32px;
    line-height: 38px;
  }
  .font-30 {
    font-size: 26px;
    line-height: 32px;
  }
}

@media (max-width: 1199px) {
}

@media (max-width: 991px) {
}

@media only screen and (max-width: 767px) {
  .mr-20 {
    margin-right: 10px;
  }
  .mb-20 {
    margin-bottom: 10px;
  }
  .mb-30 {
    margin-bottom: 15px;
  }
  .font-20 {
    font-size: 16px;
  }
  .w-60 {
    width: 100%;
  }
  .w-40 {
    width: 100%;
  }
}

@media (max-width: 575px) {
  .filters select {
    width: calc(50% - 8px);
    margin-right: 16px !important;
  }
  .filters select.second {
    margin-right: 0 !important;
  }
  .filters .search-input {
    margin-right: 0 !important;
  }
}

@media (max-width: 374px) {
  .filters select {
    width: 100%;
    margin-right: 0 !important;
  }
}

@media (min-width: 700px) {
  .w-sm-250px {
    width: 250px !important;
  }
  .w-sm-auto {
    width: auto !important;
  }
  .mb-sm-0 {
    margin-bottom: 0 !important;
  }
  .flex-sm-nowrap {
    flex-wrap: nowrap !important;
  }
}
