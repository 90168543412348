.review{
    height: 600px;
}
.review .description::-webkit-scrollbar {
    display: none;
}
.review .upload-logo{
    width: 80px;
    height: 80px;
}
.review .profile-img{
    position: relative;
    overflow: hidden;
}
.review .profile-img::before{
    position: absolute;
    content: "";
    width: 140%;
    height: 140%;
    top: -17px;
    left: -14px;
    border: 15px solid white;
    background: transparent;
    opacity: .5;
    border-radius: 50px;
    box-shadow: inset 0 5px white;
}