.holders {
  padding: 80px 30px 0;
}
.holders .thumb-horizontal,
.thumb-vertical {
  display: block;
}
.row-count {
  padding: 2px 7px !important;
  font-size: 13px !important;
  color: #868686 !important;
}
.row-count:focus {
  box-shadow: none;
}
.pagination-section ul {
  margin: 0;
}
.pagination-number {
  width: 30px;
  height: 30px;
}
.disabled {
  cursor: not-allowed;
  opacity: 0.6;
}
.pagination-number a {
  width: 100%;
  height: 100%;
}
.pagination-number a:focus {
  box-shadow: none;
}
.pagination-number a:hover,
.pagination-number a:hover i {
  background-color: #e8f0fe;
  color: #0d0f12;
}
.pagination-number i {
  line-height: 15px;
  color: #868686;
  width: fit-content;
}

@media only screen and (max-width: 768px) {
  .holders {
    padding: 120px 15px 0;
  }
  .holder-form {
    margin-top: 20px;
  }
}
@media only screen and (max-width: 460px) {
  .pagination-section {
    margin-top: 10px;
  }
}
